var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-rect',{attrs:{"config":{
    x: _vm.y,
    y: _vm.x,
    width: _vm.blockSize,
    height: _vm.blockSize,
    fill: this.fill,
    strokeWidth: 0.1,
    stroke: 'grey',
  },"onMouseEnter":_vm.onMouseEnter,"onMouseLeave":_vm.onMouseLeave},on:{"click":_vm.select,"tap":_vm.select}})
}
var staticRenderFns = []

export { render, staticRenderFns }