var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layer',{ref:"marks"},[_vm._l((42),function(i){return _c('v-text',{key:`column-${i}`,attrs:{"config":{
      ..._vm.topMarkConfig,
      text: String(i),
      x: _vm.blockSize * (i - 1),
      //fill: i === selectedTop ? 'black' : 'white',
    }}})}),_vm._l((24),function(i){return _c('v-text',{key:`row-${i}`,attrs:{"config":{
      ..._vm.leftMarkConfig,
      text: _vm.letterFromNumber(i),
      y: _vm.blockSize * i,
      //fill: i + 1 === selectedLeft ? 'black' : 'white',
    }}})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }