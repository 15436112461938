var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-btn',{staticClass:"ma-1",style:({
    border:
      _vm.color === _vm.currentColor
        ? '2px solid red !important'
        : '1px solid lightgray',
    background: _vm.color,
  }),attrs:{"rounder":"true","x-small":"","fab":""},on:{"click":function($event){return _vm.select()}}})
}
var staticRenderFns = []

export { render, staticRenderFns }