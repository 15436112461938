var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"value":_vm.value,"width":"500"},on:{"input":function($event){return _vm.$emit('input', $event)}}},[_c('v-card',[_c('RetroCardTitle',{attrs:{"closable":""},on:{"close":function($event){return _vm.setDialog(false)}}},[_vm._v("Connect Wallet")]),_c('div',{class:!_vm.$isMobile()
          ? 'd-flex justify-center align-center py-5'
          : 'flex-column'},[_c('v-card-actions',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$isMobile()),expression:"!$isMobile()"}],staticClass:"flex-column"},[_c('v-btn',{attrs:{"height":"auto","width":"auto"},on:{"click":function($event){_vm.connectWallet();
            _vm.setDialog(false);}}},[_c('v-img',{attrs:{"contain":"","src":require("@/assets/UI-icon_METAMASK.svg"),"height":"150px","width":"150px"}})],1),_c('v-card-text',{staticClass:"text-center"},[_vm._v(" Connect with Metamask ")])],1),_c('v-divider',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$isMobile()),expression:"!$isMobile()"}],attrs:{"vertical":""}}),_c('v-card-actions',{directives:[{name:"show",rawName:"v-show",value:(_vm.$isMobile()),expression:"$isMobile()"}]},[_c('v-btn',{attrs:{"height":"auto","width":"auto"},on:{"click":function($event){_vm.openDeepLink();
            _vm.setDialog(false);}}},[_c('v-img',{attrs:{"height":"auto","width":"auto","contain":"","src":require("@/assets/UI-icon_METAMASK.svg")}})],1),_c('v-card-text',{staticClass:"text-left"},[_vm._v(" Connect via Metamask Browser ")])],1),_c('v-card-actions',{class:!_vm.$isMobile() ? 'flex-column' : 'flex-row'},[_c('v-btn',{attrs:{"height":"auto","width":"auto"},on:{"click":function($event){_vm.useWalletConnect();
            _vm.setDialog(false);}}},[_c('v-img',{attrs:{"contain":"","src":require("@/assets/UI-icon_WALLETCONNECT.svg"),"height":!_vm.$isMobile() ? '150px' : 'auto',"width":!_vm.$isMobile() ? '150px' : 'auto'}})],1),_c('v-card-text',{class:!_vm.$isMobile() ? 'text-center' : 'text-left'},[_vm._v(" Connect via WalletConnect ")])],1)],1),_c('v-divider',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$isMobile()),expression:"!$isMobile()"}]}),_c('v-card-actions',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$isMobile()),expression:"!$isMobile()"}]},[_c('v-btn',{attrs:{"color":"accent lighten-1 primary--text"},on:{"click":function($event){_vm.addTokenToWallet();
          _vm.setDialog(false);}}},[_vm._v(" Add to Metamask ")]),_c('v-card-text',[_vm._v(" Add the BLS token automatically ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }