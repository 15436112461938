var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{key:_vm.poster.posterUniqueId,staticClass:"mb-5 black",staticStyle:{"box-sizing":"border-box","border":"1px solid grey !important"},attrs:{"elevation":"5","width":_vm.width}},[_c('v-card-title',{staticClass:"d-flex flex-row align-center justify-space-between pa-2",staticStyle:{"border-bottom":"1px solid grey"}},[_c('div',{staticClass:"d-flex flex-row align-center justify-center"},[(!this.profilePicture)?_c('v-icon',{staticClass:"accent--text text--darken-2 mr-2",attrs:{"dense":""}},[_vm._v("mdi-circle ")]):_c('v-avatar',{staticClass:"mr-2",style:({ border: `3px solid ${this.hexBorder}`, cursor: 'pointer' }),attrs:{"size":_vm.$vuetify.breakpoint.mobile ? '28' : '36'},on:{"click":function($event){return _vm.toPublicProfile(_vm.poster.walletAddress)}}},[_c('img',{attrs:{"src":this.profilePicture,"alt":"profile icon"}})]),(!this.displayName)?_c('div',{staticClass:"grey--text text-body-2"},[_vm._v(" "+_vm._s(_vm.poster.posterUniqueId.substring(0, 4))+"..."+_vm._s(_vm.poster.posterUniqueId.substring(_vm.poster.posterUniqueId.length - 4))+" ")]):_c('span',{class:_vm.$vuetify.breakpoint.mobile
            ? 'grey--text text-caption'
            : 'grey--text text-body-2',staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toPublicProfile(_vm.poster.walletAddress)}}},[_vm._v(_vm._s(this.displayName))])],1),_c('div',{staticClass:"d-flex flex-row align-center justify-center",staticStyle:{"font-size":"12px"}},[_c('div',{staticClass:"mx-2"},[_c('v-icon',{staticClass:"grey--text text--darken-2",attrs:{"dense":""}},[_vm._v("mdi-calendar-month ")]),_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.poster.timestamp))+" ")],1),_c('div',{staticClass:"mx-2"},[_c('v-icon',{staticClass:"grey--text text--darken-2",attrs:{"dense":""}},[_vm._v("mdi-square ")]),_vm._v(" "+_vm._s(_vm.blocksCount(_vm.poster.blockStart, _vm.poster.blockEnd))+" ")],1),_c('v-icon',{staticClass:"copy",attrs:{"dense":""},on:{"click":function($event){return _vm.copyUrl()}}},[_vm._v(" mdi-content-copy ")])],1)]),_c('div',{staticClass:"d-flex flex-column align-center justify-center"},[_c('img',{class:_vm.$vuetify.breakpoint.mobile ? 'image-mobile' : 'image',attrs:{"src":_vm.poster.publicUrlBig},on:{"click":function($event){return _vm.oneClick(_vm.poster.posterUniqueId)}}})]),(_vm.poster.imageText)?_c('v-card-subtitle',{staticClass:"d-flex flex-row align-center justify-center"},[_c('div',{style:(_vm.$isMobile() && 'max-width: 325px;')},[_vm._v(" "+_vm._s(_vm.poster.imageText)+" ")]),(_vm.isUrl(_vm.poster.imageText))?_c('v-icon',{staticClass:"grey--text text--darken-2 mr-2",staticStyle:{"cursor":"pointer"},attrs:{"dense":""},on:{"click":function($event){return _vm.openUrl(_vm.poster.imageText)}}},[_vm._v("mdi-chevron-right ")]):_vm._e()],1):_vm._e(),_c('v-card-text',{staticClass:"top-border",class:_vm.$vuetify.breakpoint.mobile
        ? 'd-flex flex-column align-center justify-space-between pa-2 ma-0'
        : 'd-flex flex-row align-center justify-space-between pa-2 ma-0'},[_c('div',{staticClass:"d-flex flex-row align-center justify-center mx-2",staticStyle:{"font-size":"12px"}},[_c('v-icon',{staticClass:"grey--text text--darken-2 mr-2",attrs:{"dense":""}},[_vm._v("mdi-thumb-up mdi-flip-h ")]),_vm._v(" "+_vm._s(_vm.poster.likes)+" ")],1),_c('v-card-actions',{class:_vm.$vuetify.breakpoint.mobile
          ? 'd-flex flex-row align-center justify-center pa-0 mt-2'
          : 'pa-0 ma-0',style:(_vm.$vuetify.breakpoint.mobile && 'width: 100% !important;')},[(_vm.connected)?_c('v-btn',{class:_vm.alreadyLiked
            ? 'grey darken-4 black--text'
            : 'yellow darken-1 black--text',attrs:{"width":_vm.$vuetify.breakpoint.mobile ? '50%' : '125',"text":"","loading":_vm.likesInProgress === true &&
            _vm.posterIdClicked === _vm.poster.posterUniqueId},on:{"click":function($event){return _vm.likePoster(_vm.poster.posterUniqueId)}}},[(!_vm.alreadyLiked)?_c('span',[_vm._v("LIKE")]):_c('v-icon',{staticClass:"yellow--text text--darken-2 mr-2",attrs:{"dense":""}},[_vm._v("mdi-thumb-up mdi-flip-h ")])],1):_vm._e(),(_vm.connected)?_c('v-btn',{staticClass:"accent darken-1 black--text",attrs:{"width":_vm.$vuetify.breakpoint.mobile ? '50%' : '125',"text":""},on:{"click":function($event){return _vm.tweet()}}},[_vm._v("TWEET")]):_vm._e(),(!_vm.connected)?_c('div',[_c('ConnectButton',{attrs:{"mini":""}})],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }