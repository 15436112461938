<template>
  <v-row>
    <v-col
      md="4"
      lg="3"
      cols="12"
      v-for="(space, index) in spacesList"
      :key="index"
    >
      <SpaceTile
        :spaceId="space.spaceId"
        :title="space.title"
        :image="space.spaceImageUrl"
        :subtitle="space.subtitle"
        :active="space.active"
        :disabled="space.disabled"
        :to="space.to"
      />
    </v-col>
    <v-spacer />
  </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";
import * as posterService from "../../services/posterService.js";
import SpaceTile from "./SpaceTile.vue";

export default {
  props: {
    active: Boolean,
    archived: Boolean,
  },
  components: {
    SpaceTile,
  },
  computed: {
    ...mapState("space", ["spaces"]),
  },
  asyncComputed: {
    async spacesList() {
      return (
        Object.entries(this.spaces)
          .filter(([_, space]) => !space.config.hidden) // eslint-disable-line
          .filter(
            // eslint-disable-next-line
            ([_, space]) =>
              (this.active && space.config.active) ||
              (this.archived && !space.config.active)
          )
          // eslint-disable-next-line
          .map(([_, space]) => {
            const spaceImageUrl = this.getSpaceImage(space.config);
            return {
              spaceId: space.config.spaceId,
              spaceImageUrl: spaceImageUrl,
              title: space.config.name,
              subtitle: space.config.subtitle,
              active: space.config.active,
              to: `/${space.config.slug}`,
              sort: space.config.sort,
            };
          })
          .sort((a, b) => a.sort - b.sort)
      );
    },
  },
  methods: {
    ...mapActions("space", ["setSpaceSwitcher"]),

    async getSpaceImage(spaceConfig) {
      if (spaceConfig.spaceImageUrl) {
        return Promise.resolve(spaceConfig.spaceImageUrl);
      }

      return posterService
        .spaceImageUrl(spaceConfig.spaceId)
        .then((imageUrl) => {
          return `${imageUrl}?t=${Date.now()}`;
        })
        .catch((err) => console.log(err));
    },
  },
  async created() {
    this.setSpaceSwitcher(true);
  },
};
</script>

<style lang="scss">
.space-image {
  .foreground {
    width: 100%;
  }
}
</style>
