var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"promo"},[_c('img',{staticClass:"title ma-3",attrs:{"src":require("@/assets/mint/promo-title.svg")}}),_c('img',{staticClass:"albert",attrs:{"src":require("@/assets/mint/albert4.svg")}}),_c('div',{staticClass:"fact black--text text-uppercase ml-3 mb-3 mt-1",class:{
      'text-subtitle-2 mb-4': _vm.$vuetify.breakpoint.lgAndDown,
      'text-h6 mb-8': _vm.$vuetify.breakpoint.xlOnly,
    }},[_vm._m(0),_vm._m(1),_vm._m(2)]),_c('div',{staticClass:"cta black--text text-uppercase ml-3 mb-3 mt-1",class:{
      'text-subtitle-2 mb-4': _vm.$vuetify.breakpoint.lgAndDown,
      'text-h6 mb-8': _vm.$vuetify.breakpoint.xlOnly,
    }},[_vm._m(3)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{staticClass:"accent--text"},[_vm._v("Extremely")]),_c('span',{staticClass:"white--text font-weight-bold"},[_vm._v("rare")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{staticClass:"accent--text"},[_vm._v("Extremely")]),_c('span',{staticClass:"yellow--text font-weight-bold"},[_vm._v("powerful")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{staticClass:"accent--text"},[_vm._v("Smart")]),_c('span',{staticClass:"white--text font-weight-bold text-none"},[_vm._v("NFTs")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"text-subtitle-2",attrs:{"href":"https://1000blocks.medium.com/extremely-rare-extremely-powerful-1000blocks-first-smart-nft-collection-84e8562e59f8","target":"_blank"}},[_c('span',{staticClass:"caron"},[_vm._v(">")]),_vm._v(" Learn more ")])
}]

export { render, staticRenderFns }