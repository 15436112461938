<template>
  <v-container class="py-3">
    <!-- MOBILE -->
    <OverviewToolbar
      class="overview-mobile-menu pt-2 pb-2 black"
      v-if="$isMobile()"
    />

    <OverviewGrid />

    <Spaces active />

    <!-- <OverviewTitle>Staking Pools</OverviewTitle> -->
    <!-- <StakingPools /> -->

    <OverviewTitle>Archive</OverviewTitle>
    <Spaces archived />
  </v-container>
</template>

<script>
import OverviewTitle from "../components/overview/OverviewTitle.vue";
import Spaces from "../components/overview/Spaces.vue";
import OverviewGrid from "../components/overview/OverviewGrid.vue";
// import StakingPools from "../components/overview/StakingPools.vue";
import OverviewToolbar from "../components/overview/OverviewToolbar.vue";

export default {
  components: {
    Spaces,
    OverviewTitle,
    OverviewGrid,
    // StakingPools,
    OverviewToolbar,
  },
  created() {
    // this.$store.dispatch("vault/load");
  },
};
</script>

<style lang="scss">
.overview-mobile-menu {
  width: auto;
  z-index: 1;
  margin: -12px -12px 16px;
}
</style>
