var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$vuetify.breakpoint.mobile
      ? 'd-flex flex-column align-start justify-center pa-3'
      : 'd-flex flex-row align-start justify-center pa-5'},[_c('v-card',{class:_vm.$vuetify.breakpoint.mobile ? 'mb-5' : 'mr-5',staticStyle:{"box-sizing":"border-box","border":"1px solid grey","background":"black"},attrs:{"width":_vm.width,"elevation":"5","loading":_vm.loading}},[_c('v-card-title',{class:_vm.$vuetify.breakpoint.mobile && 'pa-2',staticStyle:{"border-bottom":"1px solid grey"}},[_c('v-icon',{staticClass:"accent--text text--darken-2 mr-4",attrs:{"dense":""}},[_vm._v("mdi-circle ")]),_c('span',{class:this.$vuetify.breakpoint.mobile
            ? 'accent--text text-body-2'
            : 'accent--text text-h6'},[_vm._v(" PUBLIC PROFILE ")])],1),_c('div',{staticClass:"d-flex flex-row align-center justify-center pa-5",staticStyle:{"border-bottom":"1px solid grey"}},[_c('v-avatar',{style:({ border: this.hexBorder }),attrs:{"size":"180","color":"grey darken-2"}},[(this.profilePicture !== '')?_c('img',{attrs:{"src":this.profilePicture}}):_c('img',{attrs:{"src":require("@/assets/no_nft.png")}})])],1),_c('v-card-text',{class:_vm.$vuetify.breakpoint.mobile
          ? 'd-flex flex-row align-end justify-center text-center pa-2 '
          : 'd-flex flex-row align-end justify-center text-center',staticStyle:{"border-bottom":"1px solid grey"}},[_c('div',{staticClass:"d-flex flex-column align-left justify-space-between",staticStyle:{"overflow":"hidden"}},[_c('span',{staticClass:"yellow--text text-subtitle-1"},[_vm._v("DISPLAY NAME")]),(!this.displayName)?_c('span',{staticClass:"white--text text-h6"},[_vm._v(" @NAME ")]):_c('span',{staticClass:"white--text text-h6",staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(this.displayName))])])]),_c('v-card-text',{class:_vm.$vuetify.breakpoint.mobile
          ? 'd-flex flex-row align-end justify-center text-center pa-2'
          : 'd-flex flex-row align-end justify-center text-center',staticStyle:{"border-bottom":"1px solid grey"}},[_c('div',{staticClass:"d-flex flex-column align-left justify-space-between"},[_c('span',{staticClass:"yellow--text text-subtitle-1"},[_vm._v("TWITTER HANDLE")]),(this.twitterHandle === '')?_c('span',{staticClass:"white--text text-h6"},[_vm._v("@HANDLE")]):_c('span',{staticClass:"white--text text-h6"},[_vm._v(_vm._s(this.twitterHandle))])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }