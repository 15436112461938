import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueKonva from 'vue-konva';
import store from './store'
import vuetify from './plugins/vuetify'
import VueNumber from 'vue-number-animation'
import VueMobileDetection from 'vue-mobile-detection'
import VueMeta from 'vue-meta'
import {analytics} from './services/firebase'
import VueCountdownTimer from 'vuejs-countdown-timer';
import AsyncComputed from 'vue-async-computed'
import VueSocialSharing from 'vue-social-sharing'

Vue.prototype.$analytics = analytics;

Vue.config.productionTip = false

Vue.use(VueKonva);
Vue.use(VueNumber)
Vue.use(VueMobileDetection)
Vue.use(VueMeta)
Vue.use(VueCountdownTimer)
Vue.use(AsyncComputed)
Vue.use(VueSocialSharing);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
